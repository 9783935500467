
const aboutLessonQuiz = 'The Lesson Quiz helps assess your understanding of the lesson material.';

/**
 * Texts
 */
const Texts = {
    noLessTemplates: 'The student needs to create the  lesson outcome from a blank document so there is no starting template for this lesson.',
    reportDateRangePlea: 'Please specify below the period within which to generate the <b>Student Progress Report</b>.',
    successGenStdRept: 'Student report was successfully generated.',
    successMergeLogin: 'Your profile was successfully merged.',
    loginMergePlea: 'If your teacher has given you a new username and password, we need to merge it with your last year\'s username and password in order to keep track of your progress. Please provide your last years\' username and password below.',
    emptyContent: 'We could not find any <b>Quick Help Content</b> for this section at the moment.',
    successSaveLanSettings: 'The language settings were successfully updated.',
    errors: {
        defLan: 'Cannot remove default language',
        noLessons: {
            general: 'There are no lessons for this course at the moment.',
            MEE: 'There no lessons aligned to this goal at the moment.'
        },
        failedToLoginToOld: 'We could not login you to your old at this moment. Please try again in a couple of minutes.',
        invalidPWD: 'A valid password must be supplied.',
        invalidUsername: 'A valid base username must be supplied.',
        invalidNoLogins: 'Number of logins must be 1 or greater.',
        duplicateInst: 'Please use a different name for the institution.',
        instExists: 'Error: an institution with the same name already exists.',
        duplicateEmail: 'Please use a different email address.',
        emailExists: 'Error: this email is already in use.',
        contactPerson: {
          firstname: 'Contact person: first name is required.',
          lastname: 'Contact person: last name is required.',
          email: 'Contact person: email is required.',
          role: 'Contact person: role is required.',
        },
        invalidCellphone: 'The cellphone is invalid',
        instPostAddrReq: 'Institution postal address is required.',
        instProvStateReq: 'Institution province/state is required.',
        instAreaReq: 'Institution area is required.',
        instCountryReq: 'Institution country is required.',
        instNameReq: 'Institution name is required.',
        instTypeReq: 'Institution type is required.',
        serverError: 'Something went wrong on our side. Please try again later.',
        generalError: 'There was an error while we tried to load your data',
        sessionExpired: 'Your session has expired. Please login again.',
        doesNotExist: 'We could not find this account. Please register to get started.',
        invalidEmail: 'The email is invalid',
        incorrectCreds: 'Incorrect login credentials, please try again.',
        usernameExists: 'This username already exists, please choose a different username',
        usernameExists1: 'This username already exists.',
        accountSuspended: 'Your account was suspended. Please contact us via support@computers4kids.co.za for help'
    },
    emailExists: 'This email is already in use.',
    _emailExists: 'This email is already in use. Please use a different email address.',
    titles: {
        aboutAutoRenew: 'About Subscription Auto Renew'
    },
    aboutAutoRenew: 'When you enable this option, we will automatically renew your subcsription and your method of payment will be charged when the current subscription plan expires. ',
    errorOrderId: 'The order Id is invalid.',
    errorPaymentInit: 'We cannot initialize your payment at this moment.',
    company: 'Computers 4 Kids',
    allowPops: 'Your might need to allow pop-ups from classesonline.mobi',
    bSure: 'Be sure to check the spam box if you can\'t find the email in your inbox.',
    ifOldCurrs: 'If you need to access curriculums that are not migrated (i.e. not present on this page), please click the button below to access them:',
    forStdsWE: 'For students without an amail address, please contact your teacher/facilitator.',
    noAccount: 'You have no account with us, please register or contact us to get started.',
    aboutCol_Keep: [
        'Welcome to the premier Digital Skills Curriculum portal that covers 21<sup>st</sup> Century ICT skills whilst integrating classroom content including math, sciences, arts, and more!',
        'Covering Microsoft and Google apps, why not get started today.',
        'Please contact us for a free demo by <a class="kjs-el" href="mailto:support@computers4kids.co.za?subject=Computers 4 Kids Trial">clicking here&raquo;</a>'
    ],
    aboutCol: [
        'Welcome to the premier Digital Skills Curriculum portal that covers 21<sup>st</sup> Century ICT skills whilst integrating classroom content including math, sciences, arts, and more!',
        'Please click on the registration button below to get a 3 day free trial of our exciting curriculum options.'
    ],
    eqInfoGraphicHdr: 'This lesson is aligned to social and emotional learning themes',
    selectAVol: 'Please select a volume above',
    curriNotAvailable: 'This functionality is not available right now. Make sure your are subscribed to the required curriculums.',
    noPINSCurr: 'The selected curriculum does not use the Digital Badges System to track student progress or its implementation is not deployed.',
    noValidCurriculumHandler: 'No valid curriculum handler was found. Please make sure it is defined.',
    expired: 'This curriculum\'s subscription has expired',
    subscrExpired: 'Your subscription for this course has expired. <br>Please contact us on <a href="mailto:support@computers4kids.co.za?subject=Renew Subscriptions ([$subscription])">support@computers4kids.co.za</a> for renewal to continue access, or subscribe to a plan.',
    pleaseInput: 'Please enter a search term.',
    successSaveAccessCtr: 'Access country saved successfully.',
    theseForYourAccessCountry: 'What content you access based on your profile\'s country (applies only to your profile).',
    defaultHandler: 'This is a special account but its handler is not defined.',
    noSettings: 'There are no settings at the moment.',
    rapidTyping: 'You will practice typing skills with RapidTyping',
    successAddStdGrp: 'Groups successfully added.',
    oldLinkInfo: 'If you need to use this feature at this moment, please follow the link below to access it from the old Educator Dashboard. (You might be asked to login there.)',
    successRemoveStdsFromGrp: 'Student removed from selected group successfully.',
    successAddStdsToGrp: 'Student added to selected group successfully.',
    noStudentGroup: 'A gentle notice that you are not assigned to any group or class by your teacher. As a result, you will always see random PACE character traits each time you refresh this page or visit respective lessons. Please ask your teacher to assign you to a group/class.',
    successSaveCharTraitsSettings: 'Character traits settings saved successfully. ',
    theseForYourClass: 'These settings and preferences affect how your students/learners access classes on Classesonline',
    aboutSettingsTwo: 'Here you can set how our systems and services behave when you and your students/learners interact with them.',
    aboutSettings: 'Manage your preferences and settings across Computers 4 Kids services.',
    paceAlignedTo: 'This lesson is aligned to PACE #',
    bibleVerse1: '1 Thessalonians 5:18—In everything give thanks: for this is the will of God in Christ Jesus concerning you.',
    aboutTrait1: 'Expressing in words, actions, and thoughts, value to the actions of God and others the ways they have served me.',
    successUpdateUsrInfo: 'Account updated successfully.',
    reqFields: 'Fields marked * are required',
    guveInfo: 'Please provide the following info:',
    updateBasicInfo: 'Update your basic info',
    completeAccount: 'Update your account',
    headsUp: 'Just a heads up!',
    yourAccIncompl: 'We need some additional information from you before you start your next lesson! Please click the button below to complete your account:',
    whyPP: 'A profile picture helps personalize your account',
    aboutPI2: 'Personal info and options to manage it. You can update some of this info, like your contact details. You can also see a summary of your profiles.',
    aboutPI: 'Info about you and your preferences across Computers 4 Kids services',
    noTechTalkcontent: 'We could not find any Tech Talk content for this course/lesson yet.',
    feedbackSent: 'Feedback successfully sent',
    errorUploadingFile: 'There was an error uploading the file',
    atLeastOneFile: 'Please attach at least one file of your work.',
    invalidEmail: 'This email address is invalid: ',
    emailMustBeValid: 'Must be a valid email address.',
    yourDBs: 'Your earned digital badges are shown in full color and a green tick against the lesson.',
    aboutICDLCats: 'These are the units needed to be well-versed in this digital era:',
    ICDLNotCompl: 'You have NOT completed ',
    ICDLComplete: 'You have completed ',
    successActivatePIN: 'Digital Badge successfully activated',
    requirements: `Exam Technical Requirements & Support

    To ensure maximum compatibility and reliability, it is highly recommended that you use the most recent version of Mozilla Firefox when taking your exam. You are responsible for making adequate arrangements to be on a reliable computer with a reliable Internet connection during the exam. Furthermore, you should test out the computer and browser ahead of time, and ensure it has no technical issues that might hinder you during the exam period.
    
    If you encounter a technical error while you are taking your final exam, please immediately contact your personal program advisor or advising@uopeople.edu with as much information as you can about the error. Please inclue a print screen of more.`,
    academicIntegrity: `Academic Integrity. Please note - you must work on the Final Exam independently, with no assistance from or collaboration with other individuals, and without using any course materials including the Internet and non-electronic sources. If you suspect that a student is cheating on his/her Final Exam, or otherwise violating UoPeople's Academic Code of Conduct during the exam period, please notify your program advisor immediately. In keeping with University of the People's Academic Code of Conduct, any conduct that interferes with the quality of the educational experience at UoPeople is not allowed and may result in disciplinary action, such as course failure, probation, suspension, or dismissal. For more information on this topic, please review the section titled "Academic Integrity And The Disciplinary Process" in the University Catalog.`,
    supportC4kEmail: 'support@computers4kids.co.za',
    infoC4kEmail: 'info@computers4kids.co.za',
    retakeLesson: `This <b class='ptr' title='${aboutLessonQuiz}'>Lesson Quiz<b> was opened for you for a second attempt. You may click the button below to retake the <b class='ptr' title='${aboutLessonQuiz}'>Lesson Quiz<b>.`,
    lessonQuizRetake: `You have not completed this lesson yet. However, the <b class='ptr' title='${aboutLessonQuiz}'>Lesson Quiz<b> was opened for you for a second attempt. Click the button below to take the <b class='ptr' title='${aboutLessonQuiz}'>Lesson Quiz<b>.`,
    progressSoFar: 'Your progress so far',
    successSaveCandInfo: 'Your info was successfully saved.',
    fileTooSmall: 'File too small. Must be between 512x512px and 1000x1000px, less than 1MB',
    invalidFile: 'Invalid file supplied.',
    fileTooBig: 'File too big. Must be between 512x512px and 1000x1000px, less than 1MB.',
    uploadSizesFile: 'File size must be less than 3MB.',
    uploadSizesFileV2: 'File size must be less than 3MB, not more than 5 files.',
    uploadSizes: 'Size must be between 512x512px and 1000x1000px, less than 2MB.',
    uploadInProgress: 'Upload in progress',
    uploadHdr: 'Upload a profile picture',
    dropToUpload: 'Release to drop the file here',
    dnp: 'Drag & Drop the picture here, or click to browse/select',
    dnpFile: 'Drag & Drop the file here, or click to browse/select',
    tooManyFiles: 'Only one file is accepted at a time.',
    pretest: {
        header: 'PreTest',
        howToGiveSchoolName: `If you are enrolled at a school, give your school name in this field. If you study at home, enter 'Home School'`,
        intro: 'This is a quick test made up of 35 questions that will guide you on which path to follow between ICDL or e-Learner certification'
    },
    exam: {
        intro: 'Instructions.',
        intro2: `In the next screen, select your Teacher/Educator/Facilitator then click the 'Start Exam' button.`,
        intro3: `When you are ready to submit your paper, click the 'Submit Paper' button and wait for results to show`,
        note: 'Note: Trial, Aptitude, Training and Mock assessments do not count towards your final grade.',
        intro4: 'To have a feel of how the exam goes, whatch the video below.',
    },
    lessonQuiz: {
        header: 'Lesson Test',
        intro: 'This is a test made of up of 5 - 10 questions on the lesson you have covered so far. This test will help you assess and consolidate the knoweledge you have acquired in this lesson. You are expected to score 80% to move on to the next lesson test thereby gradually building your progress for the course/curriculum.',
        intro2: 'To have a feel of how the test goes, whatch the video below.',
        aboutDuration: 'If the quiz/test duration times out, your current answers will be automatically submitted for marking, so keep an eye on the timer.',
    },
    aboutLessonQuiz: 'The Lesson Quiz helps assess your understanding of the lesson material.',
    noProgress: 'You have not made any progress yet. We will show it here once it becomes available.',
    howToMakeProgress: {
        NONE: '',
        TBC: `You can start making progress by taking the <b class='ptr' title='${aboutLessonQuiz}'>Lesson Quiz<b>.`,
        DSC: `We will track and organise your digital badges as you progressively earn them.`,
        MEE: `We will track and organise your digital badges as you progressively earn them.`,
        CRC: `We will track and organise your digital badges as you progressively earn them.`,
        COC: `You can start making progress by taking the <b class='ptr' title='${aboutLessonQuiz}'>Lesson Quiz<b>.`,
    },
    support: {
        en_us: {
            hdr1: 'Get quick answers to your questions about Computers 4 Kids, Classesonline & e-Learner',
            hdr2: 'Let\'s familiarize you with our new learning management system!',
            howToHdr: 'Watch how to access the different curriculums, including the Digital Skills Curriculum, easily.'
        }
    }
};

export default Texts;