import React, { Suspense, useEffect, useRef } from 'react'
import Utils from '../../../lib/Utils'
import PopupHandleTitle from '../PopupHandleTitle'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import LoadingComponent from '../LoadingComponent'

/**
 * DSC resources
 * @param {object} props The props object
 * @returns 
 */
const DSCResources = ({lesson}) => {
    console.log('{DSCResources}')
    //console.log('{DSCResources}: lesson=',lesson)
    const __lesson = lesson
    lesson = lesson.lesson
    const _lesson = useRef({lessonId: lesson.lessonId, courseId: lesson.courseId})
    const [value, setValue] = React.useState('0')
    //es-lint dsibale-next-line
    const handleChange = (event, newValue) => {
        event = {};
        setValue(newValue)
    }
    useEffect(() => {
        Utils.addAnalytics({
            page: 'DSCResources',
            url: window.location.href,
            title: 'NS.DSCResources',
            lessonId: _lesson.current.lessonId,
            courseId: _lesson.current.courseId
        })
    },[])
    return (
        <>
        <div className='hfgfgf-nvfg-task- w-80 h-centred rounded-small animate__animated animate__fadeIn'>
            <h4 className='jdhd-hfg-i' dangerouslySetInnerHTML={{__html: `${__lesson.curriculumTitle} > ${__lesson.courseTitle} > ${__lesson.subCourseTitle?` ${__lesson.subCourseTitle} >`:''} ${lesson.number}. ${lesson.lessonTitle}`}}></h4>
            <PopupHandleTitle
                title={'Resources'}
                onLeftIconClick={() => {
                    Utils.closeLessonTaskPageModal()
                }}
                onRightIconClick={() => {}}
                showMinimizeButton={true}
            />
        </div>
        <div className='jshskkd w-80 centred bkg-white rounded shadow'>
            {
                lesson.resourceTypes.length === 0 ?
                <>
                </>:
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label='Resources'>
                            {
                                lesson.resourceTypes.map((res,i) => <Tab key={i} label={res.title} value={`${(i++)}`} />)
                            }
                        </TabList>
                    </Box>
                    {
                        lesson.resourceTypes.map((res,i) => {
                            //console.log('res=',res)
                            const ResourcesView = React.lazy(() => import(`../${res.viewHandler}`))
                            return (
                                <TabPanel key={i} value={`${(i++)}`}>
                                    <Suspense fallback={<LoadingComponent />}>
                                        <ResourcesView lesson={lesson} />
                                    </Suspense>
                                </TabPanel>
                            )
                        })
                    }
                </TabContext>
            }
        </div>
        </>
    )
}

export default DSCResources