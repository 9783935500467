import React from 'react'
import Assets from '../../../lib/Assets'

/**
 * No lesson resources
 * @returns 
 */
const NoLessonResources = () => {
    return (
        <div className='no-templates'>
            <img className='empty-nfgdgdg ptr' src={Assets.icons.emptyBox} alt='No templates' title='No templates for this lesson' />
            <h3>This lesson has no resources.</h3>
        </div>
    )
}

export default NoLessonResources