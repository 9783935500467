import React from 'react'
import HelpTip from './HelpTip'
import '../../css/need_help_round_popup.css'
import Utils from '../../lib/Utils'

/**
 * NeedHelpRoundPopup
 * @param {object} props The props object
 * @returns 
 */
const NeedHelpRoundPopup = props => {
    console.log('{NeedHelpRoundPopup}')
    return (
        <div onClick={() => {
            Utils.getQuickHelpInfo({...props})
        }} className='ptr rounded shadow animate__animated animate__fadeIn dl-pemsss' title='Click this to view help about this section'>
            <p>Need Help <HelpTip {...props} /></p>
        </div>
    )
}

export default NeedHelpRoundPopup